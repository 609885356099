<template>
  <li class="mb-3">
    <h4>
      {{ job.title }}
      <span style="font-weight:normal;"
        ><em>
          {{ job.startDate | formatDate }} &dash;
          {{ job.endDate | formatDate }}</em
        ></span
      >
    </h4>

    <div>
      <ul>
        <li v-for="item in job.highlights" v-bind:key="item.id">
          {{ item.text }}
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "job",
  props: ["job"],
  data: function() {
    return {};
  },
  filters: {
    formatDate: function(value) {
      if (value === "Present") {
        return "Present";
      } else if (value === -1) {
        return "";
      } else {
        let dateArray = value.split("-");
        return `${dateArray[1]}/${dateArray[0]}`;
      }
    },
  },
};
</script>
<style></style>
