<template>
  <ul style="padding:0px; ">
    <li v-if="jobs.active == true" style="list-style-type:none;padding:0px; ">
      <span class="text-muted small">
        <h3>{{ jobs.name }} - {{ jobs.location }}</h3>
      </span>
      <ul>
        <job
          v-for="positions in jobs.positions"
          v-bind:key="positions.title"
          v-bind:job="positions"
        ></job>
      </ul>
    </li>
  </ul>
</template>

<script>
import job from "./job.vue";
export default {
  name: "jobHolder",
  props: ["jobs"],
  components: {
    job,
  },
  data: function() {
    return {};
  },
  created() {},
};
</script>
<style></style>
