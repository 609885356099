<template>
  <div id="app">
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-10 mx-auto">
          <div class="text-right">
            <h1>Matthew Fellows</h1>
            <p class="text-left" v-html="this.data.main"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10 mx-auto">
          <h2>Relevant Experience</h2>
          <jobHolder
            v-for="job in sorted"
            v-bind:key="job.name"
            v-bind:jobs="job"
          ></jobHolder>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2>Skills and Certifications</h2>
          <p class="text-left skills" v-html="this.data.skills"></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2>Education</h2>
          <p>
            Bachelor of Arts - Communication<br />
            University of Arizona - Tucson<br />
            1999 - 2003
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jobHolder from "./components/jobHolder.vue";
// import skillHolder from "./components/skillHolder.vue";

export default {
  data: function() {
    return {
      data: {
        experiences: [
          {
            name: "Marvel Marketers",
            active: true,
            location: "Remote",
            positions: [
              {
                title: "Marketing Technology Developer",
                startDate: "2017-12",
                endDate: "2018-10",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Scoped and implemented custom Marketo templates based on design mockups and requirements",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Resolved rendering problems in email and landing pages on various clients including IE, Outlook and Gmail",
                    sort: 1,
                    active: true,
                  },
                  {
                    text:
                      "Developed custom Javascript solutions including: UTM tracking, GDPR, form pre-fill and background geographic lookup",
                    sort: 2,
                    active: true,
                  },
                ],
              },
              {
                title: "Solution Architect",
                startDate: "2018-10",
                endDate: "2021-10",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Developed reusable frameworks for building complex and dynamic Marketo and other MAP templates",
                    sort: 3,
                    active: false,
                  },
                  {
                    text:
                      "Utilized APIs for Extract-Transfer-Load activities and to display Marketing Automation metrics in a custom developed web app",
                    sort: 2,
                    active: true,
                  },
                  {
                    text:
                      "Optimized email and landing page templates and designs through iterative design, AB testing and personalization. ",
                    sort: 1,
                    active: true,
                  },
                  {
                    text:
                      "Developed Salesforce processes to automate complex opportunity contact role mapping utilizing Process Builder, Flows and APEX",
                    sort: 4,
                    active: true,
                  },
                ],
              },
              {
                title: "Senior Solution Architect",
                startDate: "2021-10",
                endDate: "Present",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Leading discovery, solution design and implementation for projects and initiatives including templates, integrations, migrations, health checks, audits and other system analysis",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Developing custom SQL, databases and interactive dashboards for segmentation, performance reporting, attribution and business intelligence",
                    sort: 1,
                    active: true,
                  },
                  {
                    text:
                      "Owning, optimizing and scaling the use of automated processes and workflows to reduce time and effort for internal and external stakeholders",
                    sort: 2,
                    active: true,
                  },
                ],
              },
            ],
          },

          {
            name: "Outstanding Photos",
            active: true,
            location: "Remote",
            positions: [
              {
                title: "Principle",
                startDate: "2019-06",
                endDate: "Present",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Developing a custom e-commerce CMS for managing photos",
                    sort: 1,
                    active: true,
                  },
                ],
              },
            ],
          },
          {
            name: "Bazaarvoice",
            active: true,
            location: "Austin",
            positions: [
              {
                title: "Marketing Operations Specialist",
                startDate: "2015-08",
                endDate: "2017-07",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "System administrator, technical owner of globally deployed marketing automation platform",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Technical lead and IT liaison on Marketing-led initiatives: lead management, scoring, data enrichment, lead routing and performance reporting",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Delivered on KPIs for health and maintenance of Marketo programs, lead database quality, templates and API integrations",
                    sort: 0,
                    active: true,
                  },

                  {
                    text:
                      "Developed and maintained a global library of optimized templates for emails and landing pages",
                    sort: 0,
                    active: true,
                  },
                ],
              },
            ],
          },
          {
            name: "Chronicle Books",
            active: true,
            location: "San Francisco",
            positions: [
              {
                title: "Marketing Systems Developer",
                startDate: "2010-07",
                endDate: "2014-05",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Added new features and patched a legacy e-commerce platform while scoping and developing replacement",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Developed custom Magento e-commerce theme & backend integrations for B2B and B2C store-fronts",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Implemented and supported custom platform integrations including dynamic shipping tables, product personalization engine and other tools: A/B testing, Google Analytics, Heatmapping",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Delivered development and server arcitecture roadmap to stakeholders that improved holiday performance and lowered overall hosting costs",
                    sort: 0,
                    active: true,
                  },
                ],
              },
            ],
          },
          {
            name: "Riverbed Technology",
            location: "San Francisco",
            active: true,
            positions: [
              {
                title: "Web Developer",
                startDate: "2008-12",
                endDate: "2009-12",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Maintained and developed high-traffic B2B marketing website",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Improved team efficiency by introducing version control, code reviews and automated deployment processes",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Developed and deployed new languages and localizations of core website",
                    sort: 0,
                    active: true,
                  },
                ],
              },
              {
                title: "Web System Technologist / Eloqua Administrator",
                startDate: "2009-12",
                endDate: "2010-07",
                description: "",
                active: true,
                highlights: [
                  {
                    text:
                      "Scoped and managed roll out of global Eloqua marketing automation implementation",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Developed and maintained brand identity and best practice with centralized templates library",
                    sort: 0,
                    active: true,
                  },
                  {
                    text:
                      "Created and delivered regular Eloqua system training and onboarding to global audience of users",
                    sort: 0,
                    active: true,
                  },
                ],
              },
            ],
          },

          {
            name: "Kaiser Permanente",
            location: "Oakland",

            positions: [
              {
                title: "Instructional Technologist",
                startDate: "2007-02",
                endDate: "2008-01",
                description: "",
              },
            ],
          },
          {
            name: "Splunk",
            location: "San Francisco",
            positions: [
              {
                title: "Course Developer",
                startDate: "2008-01",
                endDate: "2008-07",
                description: "",
              },
            ],
          },
          {
            name: "ELT",
            location: "San Francisco",

            positions: [
              {
                title: "Customer Support Engineer",
                startDate: "2004-04",
                endDate: "2005-03",
                description: "",
                active: true,
              },
              {
                title: "Learning Technology Consultant",
                startDate: "2005-03",
                endDate: "2007-02",
                description: "",
                active: true,
              },
            ],
          },
        ],
        skills:
          "Platforms: Marketo, Eloqua, Salesforce, Magento, Wordpress, Google Analytics, Drupal, JIRA/Confluence, AWS, Heroku, Tools/Other: Node/npm, JavaScript, Vue, Python, REST/SOAP, SQL, MySQL PostgreSQL, Excel, Tableau, Jupyter, MVC, HTML, CSS, JavaScript, Webpack, UI/UX, SEO/SEM. Certification: Marketo Certified Expert: 2016 - 2017, 2017 - 2019, 2019 - 2021 ",
        main:
          "I’m an empathetic human and full-stack developer with a passion for marketing automation living in the San Francisco Bay Area with multiple years of experience building and supporting the web.",
      },
    };
  },
  components: {
    jobHolder,
    // skillHolder,
  },
  computed: {
    sorted() {
      let sortedExperience = [];
      sortedExperience = this.data.experiences
        .slice()
        .sort((a, b) =>
          b.positions[0].startDate < a.positions[0].startDate ? -1 : 1
        );
      sortedExperience.forEach((experience) => {
        experience.positions.sort((a, b) =>
          b.startDate < a.startDate ? -1 : 1
        );
      });
      return this.data.experiences;
      // return sortedExperience;
    },
  },
  created() {},
};
</script>
